<template>
  <div>
    <div class="wrap">
      <form name="mfrm" action="/menu_management_status/index" method="post">
        <div class="con_top">
          <div class="borad-cast">
            <div class="event-board mt-05">공지사항</div>
            <div class="event-board">
              <div class="slider" v-if="boardList.length > 0">
                <VueSlickCarousel v-bind="slideOptions">
                  <div v-for="(item, index) of boardList" :key="index">
                    <button v-show="item.new" class="new-button" type="button" @click="moveToNoticeDetail(item.idx)">NEW</button>
                    <span class="notice-title" @click="moveToNoticeDetail(item.idx)">{{ item.title }}</span>
                  </div>
                </VueSlickCarousel>
              </div>
            </div>
            <div class="event-board mt-05">
              <div class="pointer" @click="moveToNotice()">MORE</div>
            </div>
          </div>

          <div class="boardLine"></div>

          <h2><span>■</span> 상담일정</h2>
        </div>

        <div class="con">
          <div class="con_table">
            <div class="float-left dp-block mt-30">
              <select class="ml-10 w-200px h-30px" v-model="idxCrmCenter" @change="search()" :disabled="isMaster || isAdmin ? false : true">
                <option value="-1">센터선택</option>
                <option value="0">본원센터</option>
                <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{ item.name }}</option>
              </select>
            </div>
            <crm-datepicker class="dp-block mg-auto mb-10" id="consult_schedule_date" v-model="consStartDate" :parentDate="consStartDate" format="yyyy-MM-dd" :language="$store.state.ko"> </crm-datepicker>
            <div class="btns mb-30">
              <a :class="{ 'btn_new2 pointer': this.idxCrmMetaPro !== 56, 'btn_new3 pointer': this.idxCrmMetaPro === 56 }" @click="search()">일반상담사일정</a>
              <!-- <a :class="{'btn_new2 pointer': this.idxCrmMetaPro === 56, 'btn_new3 pointer': this.idxCrmMetaPro !== 56}" @click="search()">쿠더상담사일정</a> -->
            </div>
            <ul class="month pl-400">
              <li>
                <a class="pointer" @click="prevDay()"><img src="@/assets/images/pre_active.png"/></a>
              </li>
              <li>
                <p>
                  {{ todayYear }}.{{ todayMonth }}.{{ todayDay }}
                  (
                  {{ todayDayOfWeek }} )
                </p>
              </li>
              <li>
                <a class="pointer" @click="nextDay()"><img src="@/assets/images/next_active.png"/></a>
              </li>
            </ul>

            <div class="search">
              <a :class="{ 'btn_new2 bold pointer': this.scheudleTime === 'Morning', 'btn_new3 bold pointer': this.scheudleTime !== 'Morning' }" @click="changeMorning('Morning')">09 : 00 ~ 15 : 30</a>
              <a :class="{ 'btn_new2 bold pointer': this.scheudleTime !== 'Morning', 'btn_new3 bold pointer': this.scheudleTime === 'Morning' }" @click="changeMorning('Afternoon')">16 : 00 ~ 23 : 00</a>
            </div>

            <div class="scheudle  mt-20">
              <table class="scheudle-table">
                <thead>
                  <tr>
                    <th width="130" class="passClass sticky">
                      상담사
                    </th>
                    <th width="90" class="sticky" v-for="(item, index) of timeLineScheudle" :key="index">{{ item }}</th>
                  </tr>
                </thead>
                <tbody v-if="loading">
                  <tr>
                    <td colspan="99">검색중입니다.</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="(item, index) of counselorList" :key="index">
                    <td class="back">{{ item.name }}</td>
                    <td
                      v-for="(subItem, idx) of item.timeTable"
                      :key="idx"
                      @click="preview(subItem)"
                      class="pointer"
                      :class="{
                        staff_off: !subItem.use,
                        reservation: subItem.reservationStatus === '예약',
                        reservation_submit: subItem.reservationStatus === '입실' || subItem.reservationStatus === 'NS',
                        reservation_cancle: subItem.reservationStatus === '당일취소' || subItem.reservationStatus === '당일변경',
                      }"
                    >
                      <span v-if="subItem.companyName">[{{ subItem.companyName }}] <br /></span>
                      <span v-else></span>
                      <span>{{ subItem.contactType }}</span>
                      <br />{{ subItem.name }}
                      <span v-if="subItem.consNum === 1"
                        ><br />
                        (첫상담)</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>
    <preview @modifyPreview="modifyPreview" @modalCloseFromPreview="onPreviewCloseModal()" :onModal.sync="onModal" :idxCrmReservation="idxCrmReservation" :reservationStatus="reservationStatus" :clientType="clientType" :idxCrmClient="idxCrmClient" :idxCrmCompany="idxCrmCompany" :isModify.sync="isModify"></preview>
    <register-modal ref="registerModal" @modalFromChild="onReginsterCloseModal()" :onRegisterModal.sync="onRegisterModal" :consStartDate.sync="consStartDate" :idxCrmStaff.sync="idxCrmStaff" :staffName.sync="staffName" :time.sync="time" :onIdxCrmCenter.sync="idxCrmCenter" :idxCrmClient.sync="idxCrmClient"></register-modal>
    <modify-modal ref="modifyModal" @modalCloseFromModify="onModifyCloseModal()" :onModifyModal.sync="onModifyModal"></modify-modal>
  </div>
</template>

<script>
import Preview from "@/pages/origin/consult/consultScheduleButton.vue";
import registerModal from "@/pages/origin/consult/consultScheduleRegister.vue";
import modifyModal from "@/pages/origin/consult/consultScheduleModify.vue";

/*  */
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: {
    Preview,
    registerModal,
    modifyModal,
    VueSlickCarousel,
  },

  data: () => ({
    time: "",
    isMaster: false,
    isAdmin: false,
    isCenterdirector: false,
    onRegisterModal: false,
    onModifyModal: false,
    onModal: false, // 주소 모달 on/off 여부
    resList: [],
    idxCrmCenter: Number(localStorage.getItem("idxCrmCenter")),
    value: "",
    isModify: false, //수정여부
    consStartDate: "", // 기간검색-시작날짜
    consEndDate: "", // 기간검색-종료날짜
    tabType: "gangnam", // 탭 검색 (금일: today, 주간: week, 월간: month)

    // 상담사 일정 테이블 (api response용)
    counselorList: [],

    // 기본 시간 리스트 (시간이 10시까지 인 경우 20:30 이런식으로 추가만 해주면 된다.)
    timeLine: [],

    // 각 시간별 일정 테이블 (로직 변환 최종 데이터)
    timeTable: [],

    today: "2020-01-01",
    todayYear: "2020",
    todayMonth: "01",
    todayDay: "01",
    todayDayOfWeek: "일",

    staffList: [],
    idxCrmStaff: -1,
    staffName: "",
    idxCrmReservation: -1,
    reservationStatus: "",
    clientType: "",
    idxCrmClient: -1,
    idxCrmCompany: -1,
    idxCrmMetaPro: -1,
    loading: true,
    typeList: [],
    scheudleTime: "Morning",
    timeLineScheudle: ["09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30"], // 09 : 00  ~ 15 : 30
    timeScheudle1: ["09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30"], // 09 : 00  ~ 15 : 30
    timeScheudle2: ["16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00"], // 16 : 00  ~ 23 : 00
    centerList: [], //센터리스트
    boardList: [], //공지리스트
    //슬라이드 옵션
    slideOptions: {
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2500,
      autoplaySpeed: 5000,
      cssEase: "ease",
    },
  }),

  watch: {
    consStartDate() {
      if (typeof this.consStartDate === "object") {
        this.today = this.formatDate(this.consStartDate);
      } else if (typeof this.consStartDate === "string") {
        this.today = this.consStartDate;
      }
      this.todayYear = this.today.split("-")[0];
      this.todayMonth = this.today.split("-")[1];
      this.todayDay = this.today.split("-")[2];
      this.todayDayOfWeek = this.getDayOfWeek(new Date(this.today).getDay());
      if (sessionStorage.getItem("type") === null) {
        this.search();
      } else {
        this.search(sessionStorage.getItem("type"));
      }
    },
  },

  created() {
    this.getCenterListAll();

    if (localStorage.getItem("auth") === "MASTER") {
      this.isMaster = true;
    } else {
      this.isMaster = false;
    }

    if (localStorage.getItem("auth") === "ADMIN") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    if (localStorage.getItem("auth") === "CENTERDIRECTOR") {
      this.isCenterdirector = true;
    } else {
      this.isCenterdirector = false;
    }
  },

  mounted() {
    this.getNotices();
    this.defaultTable();
    if (sessionStorage.getItem("consStartDate") === null) {
      this.consStartDate = this.formatDate();
    } else {
      this.consStartDate = sessionStorage.getItem("consStartDate");
    }

    if (sessionStorage.getItem("scheudleType")) {
      const scheudleType = sessionStorage.getItem("scheudleType");
      this.scheudleTime = scheudleType;
      if (scheudleType === "Morning") {
        //오전 시간표
        this.timeLineScheudle = this.timeScheudle1;
      } else {
        //오후 시간표
        this.timeLineScheudle = this.timeScheudle2;
      }
    }
    //this.asyncMount()
    if (sessionStorage.getItem("type") === null) {
      this.search();
    } else {
      this.search(sessionStorage.getItem("type"));
    }
    // 오늘 날짜 세팅
    this.today = this.formatDate();
    this.todayYear = this.today.split("-")[0];
    this.todayMonth = this.today.split("-")[1];
    this.todayDay = this.today.split("-")[2];
    this.todayDayOfWeek = this.getDayOfWeek(new Date(this.today).getDay());
  },

  methods: {
    /**
     * @description 공지사항 조회
     */
    getNotices() {
      var param = {
        page: this.page,
        pagesize: this.pagesize,
      };
      this.loading = true;
      //공지조회 api 호출
      this.axios
        .get(`/api/v1/board/One`, { params: param })
        .then((res) => {
          if (res.data.err === 0) {
            for (let index = 0; index < res.data.boardList.length; index++) {
              this.boardList.push({
                idx: res.data.boardList[index].idx,
                title: res.data.boardList[index].board_title,
                active: true,
                new: true,
              });
            }
            if (res.data.boardListCount) {
              this.listLength = res.data.boardListCount;
            }
            this.maxPagingCount = Math.ceil(this.listLength / this.pagesize);
            this.maxPagePart = Math.ceil(this.maxPagingCount / 10);
            this.pagePart = Math.ceil(this.page / 10);
          } else {
            this.boardList = [];
            // alert(res.data.err_msg);
          }
        })
        .catch((err) => {
          alert(`err : ${err}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * @description 공지 상세 페이지 이동
     */
    moveToNoticeDetail(idx) {
      this.$router.push(`/origin/notice_detail/${idx}`);
    },
    moveToNotice() {
      this.$router.push(`/origin/notice`);
    },
    /**
     * @description 상담사별 스케쥴 세팅
     */
    setScheudle() {
      for (let couselor of this.counselorList) {
        //1. 상담사의 스케쥴을 기본적으로 use:false로 한다
        const timeScheudle = this.timeLineScheudle.map((item) => {
          return {
            idxCrmStaff: couselor.idxCrmStaff,
            staffName: couselor.name,
            use: false,
            time: item,
          };
        });

        //2.상담사의 사용가능한 예약시간을 확인한다.
        const worTimeScheudle = couselor.workTime.split(",");
        for (let workTime of worTimeScheudle) {
          const isFind = timeScheudle.find((time) => time.time === workTime);
          if (isFind) {
            isFind.use = true;
          }
        }

        //3. reservationTable의 내용을 timetable에 추가한다.
        if (couselor.reservationTable.length > 0) {
          for (let reservation of couselor.reservationTable) {
            const isFind = timeScheudle.find((time) => time.time === reservation.time);
            if (isFind) {
              isFind.clientType = reservation.clientType;
              isFind.companyName = reservation.companyName;
              isFind.consNum = reservation.consNum;
              isFind.idxCrmClient = reservation.idxCrmClient;
              isFind.idxCrmCompany = reservation.idxCrmCompany;
              isFind.idxCrmReservation = reservation.idxCrmReservation;

              isFind.name = reservation.name;
              isFind.reservationStatus = reservation.reservationStatus;
              isFind.time = reservation.time;
              isFind.type = reservation.type;
              isFind.workTime = reservation.workTime;
              isFind.contactType = reservation.contactType;
              isFind.idxCrmCenter = reservation.idxCrmCenter;
            }
          }
        }
        couselor.timeTable = timeScheudle;
      }
    },

    /**
     * @description : 오전 오후 변경
     */
    changeMorning(val) {
      sessionStorage.setItem("scheudleType", val);
      if (val !== this.scheudleTime) {
        this.scheudleTime = val;
        if (val === "Morning") {
          //오전 시간표
          this.timeLineScheudle = this.timeScheudle1;
        } else {
          //오후 시간표
          this.timeLineScheudle = this.timeScheudle2;
        }
        this.search();
      }
    },

    // async search() {
    search(data) {
      if (data === "kuder") {
        this.idxCrmMetaPro = 56;
        sessionStorage.setItem("type", "kuder");
      } else {
        this.idxCrmMetaPro = -1;
        sessionStorage.removeItem("type");
      }
      var params = {
        consEndDate: this.consStartDate,
        consStartDate: this.consStartDate,
        idxCrmCenter: this.idxCrmCenter,
        kindOfPage: -1,
      };
      this.loading = true;
      this.axios
        .get("/api/v1/cons/list", {
          params: params,
        })
        .then((res) => {
          this.staffList = res.data.staffList;
          this.resList = res.data.resList;
          if (this.resList.length > 0) {
            this.counselorList = [];
            this.timeTable = [];
          } else {
            this.defaultTable();
            this.timeTable = [];
          }

          for (var i = 0; i < res.data.staffList.length; i++) {
            var consData = {
              idxCrmStaff: res.data.staffList[i].idx,
              name: res.data.staffList[i].name,
              workTime: res.data.staffList[i].work_time,
              timeTable: [],
              reservationTable: [],
            };
            const idxCrmCenter = res.data.staffList[i].idx_crm_center;

            for (var j = 0; j < res.data.resList.length; j++) {
              if (res.data.staffList[i].idx === res.data.resList[j].idxCrmStaff) {
                if (res.data.resList[j].consTime !== undefined) {
                  var consTimeArray = res.data.resList[j].consTime.split("/");

                  for (var consTime of consTimeArray) {
                    consData.reservationTable.push({
                      time: consTime,
                      name: res.data.resList[j].name,
                      type: res.data.resList[j].typeName,
                      //count: 0,
                      consNum: res.data.resList[j].consNum,
                      idxCrmReservation: res.data.resList[j].idx,
                      idxCrmStaff: res.data.resList[j].idxCrmStaff,
                      reservationStatus: res.data.resList[j].reservationStatus,
                      clientType: res.data.resList[j].clientType,
                      idxCrmClient: res.data.resList[j].idxCrmClient,
                      idxCrmCompany: res.data.resList[j].idxCrmCompany,
                      companyName: res.data.resList[j].companyName,
                      contactType: res.data.resList[j].contactType,
                      workTime: true,
                      idxCrmCenter: idxCrmCenter,
                    });
                  }
                }
              }
            }
            this.counselorList.push(consData);
          }
          //상담사 이름순 정렬
          this.counselorList.sort((item1, item2) => {
            if (item1.name < item2.name) return -1;
            else if (item1.name === item2.name) return 1;
            else return 0;
          });
          this.setScheudle();
        })
        .catch((err) => {
          console.error(err.response);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onCalendar() {
      document.getElementById("consult_schedule_date").childNodes[0].childNodes[2].style.display = "block";
    },

    defaultTable() {
      this.counselorList = [];
    },

    // 상담일지 미리보기 (모달 on)
    preview(subItem) {
      sessionStorage.setItem("consStartDate", this.formatDate(this.consStartDate));
      const { userInfo } = this.$store.state;
      if (subItem.use === true) {
        if (subItem.idxCrmReservation !== undefined) {
          this.idxCrmReservation = subItem.idxCrmReservation;
          this.reservationStatus = subItem.reservationStatus;
          this.idxCrmClient = subItem.idxCrmClient;
          this.idxCrmCompany = subItem.idxCrmCompany;
          this.clientType = subItem.clientType;
          this.onModal = !this.onModal;

          if (userInfo.auth === "MASTER" || Number(localStorage.getItem("idxCrmCenter")) === subItem.idxCrmCenter) {
            this.isModify = true;
          } else {
            this.isModify = false;
          }
        } else {
          this.onRegisterModal = !this.onRegisterModal;
          this.idxCrmStaff = subItem.idxCrmStaff;
          this.staffName = subItem.staffName;
          this.time = subItem.time;
          this.$refs.registerModal.radio();
          this.$refs.registerModal.getStaffSchedule2(this.idxCrmStaff, this.staffName, this.consStartDate);
          this.$refs.registerModal.getClientList(this.idxCrmStaff);
          this.$refs.registerModal.getPersonList();
        }
      }
    },

    modifyPreview() {
      this.onModal = !this.onModal;
      this.onModifyModal = !this.onModifyModal;
      this.idxCrmReservation = this.idxCrmReservation;
      this.$refs.modifyModal.getCustomerData(this.idxCrmClient);
      this.$refs.modifyModal.getReservation(this.idxCrmReservation);
    },

    // 상담일지 닫기 (모달 off)
    offModal() {
      this.onModal = false;
    },

    // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
    formatDate(date) {
      if (date) {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
      } else {
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
      }
    },

    // 요일 가져오기
    getDayOfWeek(index) {
      switch (index) {
        case 0:
          return "일";
        case 1:
          return "월";
        case 2:
          return "화";
        case 3:
          return "수";
        case 4:
          return "목";
        case 5:
          return "금";
        case 6:
          return "토";
      }
    },

    // 다음 날로 옮기기
    nextDay() {
      // 다음 날이 다음달인 경우 달력 테이블 변경
      var date = new Date(this.today);
      var prevDate = new Date(this.today);
      // if ()
      // this.onMappingCalendar()

      date.setDate(date.getDate() + 1);

      this.today = this.formatDate(date);
      this.consStartDate = this.today;
      this.todayYear = this.today.split("-")[0];
      this.todayMonth = this.today.split("-")[1];
      this.todayDay = this.today.split("-")[2];
      this.todayDayOfWeek = this.getDayOfWeek(new Date(this.today).getDay());

      if (sessionStorage.getItem("type") === null) {
        this.search();
      } else {
        this.search(sessionStorage.getItem("type"));
      }
    },

    // 이전 날로 옮기기
    prevDay() {
      var date = new Date(this.today);
      var prevDate = new Date(this.today);

      date.setDate(date.getDate() - 1);
      this.today = this.formatDate(date);
      this.consStartDate = this.today;
      this.todayYear = this.today.split("-")[0];
      this.todayMonth = this.today.split("-")[1];
      this.todayDay = this.today.split("-")[2];
      this.todayDayOfWeek = this.getDayOfWeek(new Date(this.today).getDay());
      if (sessionStorage.getItem("type") === null) {
        this.search();
      } else {
        this.search(sessionStorage.getItem("type"));
      }
    },

    // 상담예약관리 상세정보 페이지로 이동
    detail(idx) {
      this.$router.push(`/origin/consult_reserve_detail?idx=${idx}`);
    },

    // 상담예약 등록페이지로 이동
    register() {
      this.$router.push("/origin/consult_reserve_register");
    },

    /**
     * @description  등록 모달 닫기
     */
    onReginsterCloseModal() {
      this.onRegisterModal = false;
      this.search();
    },

    /**
     * @description 미리보기 등록 모달 닫기
     */
    onPreviewCloseModal() {
      this.onModal = false;
      this.search();
    },

    /**
     * @description 미리보기 수정 모달 닫기
     */
    onModifyCloseModal() {
      this.onModifyModal = false;
      this.search();
    },

    /**
     * @description : 센터 리스트 추출
     */
    getCenterListAll() {
      this.axios
        .get("/api/v1/center/all", {})
        .then((res) => {
          if (res.data.err === 0) {
            this.centerList = res.data.centerList;
          } else {
            this.centerList = [];
          }
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },
  },
};
</script>
<style lang="scss">
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

.slider {
  margin-bottom: 20px;
}

.con_top {
  margin-top: 15px;
}

.borad-cast {
  display: flex;
  justify-content: space-between;
}

.slick-list {
  width: 1150px;
}

.event-board {
  display: inline-block;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.new-button {
  width: 40px;
  height: 25px;
  background-color: #ff5f00;
  border-radius: 18px;
  border: none;
  color: #fff;
  font-family: "Noto Sans KR";
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  cursor: pointer;
}

.notice-title {
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: -0.8px;
  text-align: left;
  color: #666;
}
.boardLine {
  width: 99.8vw;
  margin-bottom: 36px;
  margin-left: calc(-49.9vw + 50%);
  border-bottom: solid 1px #e6e6e6;
  background-color: #e6e6e6;
}
.scheudle {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 600px;

  .scheudle-table {
    width: 100%;
    text-align: center;
    margin-bottom: 0 !important;
    border-top: 1px solid #fff;
    letter-spacing: -0px;
    clear: both;
    font-size: 13px;
    line-height: 20px;
    border-collapse: separate;
    border-spacing: 0;

    thead tr th {
      border-right: 1px solid #e1e1e1;
      border-bottom: 0.3em solid #d8d8d8 !important;
      padding: 8px 15px;
      background: #f4f4f4;
      font-weight: bold;
      border-top: 1px solid #777;
    }

    tbody tr:nth-child(2n-2) td {
      border-bottom: 0.3em solid #d8d8d8;
    }
  }
}
.sticky {
  position: sticky;
  top: 0;
}
</style>
